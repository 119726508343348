<template>
  <div>
    <NavTitle class="mb16" title="顾问列表" :showBack="false">
      <template #right>
        <div class="flex flex-end items-center">
          <el-button type="primary" @click="$refs.consulantPopupRef.open()">新建+</el-button>
          <div class="input-row mr16 ml16">
            <el-input class="input" placeholder="请输入顾问ID/手机号/姓名" clearable v-model="keyword" @change="getConsulantList"></el-input>
            <el-button type="primary" icon="el-icon-search"  @click="getConsulantList"></el-button>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="table-panel">
      <el-table :data="consulantList" style="width: 100%" 
        :header-cell-style="{background: '#f0f1f3', color: '#333'}"
        v-loading="tableLoading">
        <el-table-column
          prop="uid"
          label="顾问ID">
        </el-table-column>
        <el-table-column
          prop="consultantPortrait"
          label="顾问头像">
          <template slot-scope="scope">
            <LoadImage class="avatar" :src="scope.row.consultantPortrait" preview></LoadImage>
          </template>
        </el-table-column>
        <el-table-column
          prop="consultantName"
          label="顾问名称">
        </el-table-column>
        <el-table-column
          label="联系卡片">
          <template slot-scope="scope">
            <LoadImage class="img-contact" :src="scope.row.contactCardImg" preview></LoadImage>
          </template>
        </el-table-column>
        <el-table-column
          prop="contactInfo"
          label="联系方式">
        </el-table-column>
        <el-table-column
          prop="weight"
          label="权重">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <span class="theme-text pointer" @click="$refs.consulantPopupRef.open({isEdit: true, detail: scope.row})">编辑</span>
            <span class="red-text pointer ml16" @click="handleDelete(scope.row.uid)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 编辑弹窗 -->
    <EditConsulantPopup ref="consulantPopupRef" @update="getConsulantList"></EditConsulantPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import LoadImage from '@/components/LoadImage'
import EditConsulantPopup from './components/EditConsulantPopup.vue'
import { consultantManageApi } from '@/api/admin.js'
export default {
  name: 'ConsulantList',
  components: {
    NavTitle,
    LoadImage,
    EditConsulantPopup
  },
  data() {
    return {
      keyword: '',
      consulantList: [],
      tableLoading: false
    }
  },
  created() {
    this.getConsulantList()
  },
  methods: {
    getConsulantList() {
      consultantManageApi({
        act: 0
      }).then(res => {
        console.log('商务列表', res)
        this.consulantList = res.data.consultantInfos || []
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleDelete(uid) {
      this.$confirm('确认删除吗？').then(action => {
        consultantManageApi({
          act: 2,
          consultantInfo: { uid }
        }).then(res => {
          this.$tips({message: res.msg, type:'success'})
          this.getConsulantList()
        }).catch(err => {
          this.$tips({message: err, type:'error'})
        })
      }).catch(err => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 300px;
  display: flex;
  align-items: center;
}
.table-panel {
  background: #fff;
  padding: 10px;
  margin: 16px 20px 0;
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
}
.img-contact {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}
</style>